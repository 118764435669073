import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import './employee-role.scss';

interface IProps {
  role: string;
}

export const CSEmployeeRole: React.FC<IProps> = ({ role }) => {
  const popoverRole = (
    <Popover id="popover-role">
      <Popover.Header as="h3">{role}</Popover.Header>
      <Popover.Body>
        {role === 'Defender' && (
          <>
            Protects allies from the front line. Has significantly high DEF, and
            taunts enemies.
          </>
        )}
        {role === 'Ranger' && (
          <>
            A powerful ranged attacker. Has high CRIT and HIT, and needs
            protection because of low HP.
          </>
        )}
        {role === 'Siege' && (
          <>
            A siege unit that charges straight at enemy Bosses. Attacks nearby
            enemies if no Boss is present.
          </>
        )}
        {role === 'Sniper' && (
          <>
            A hindmost firepower supporter. Has noticeably high HIT, and is
            effective against Strikers.
          </>
        )}
        {role === 'Striker' && (
          <>
            An outrider that disrupts enemy lines. Has high EVA and HIT, and
            specializes in close combat.
          </>
        )}
        {role === 'Supporter' && (
          <>A Support role that heals allies or weakens enemies.</>
        )}
        {role === 'Tower' && (
          <>
            A stationary unit. Used for base protection purposes, and has
            special combat support functions.
          </>
        )}
        {role === 'Rearm' && (
          <>
            Rearmed units develop a Leader skill. Their Leader skill is
            activated when they're selected as Leader.
          </>
        )}
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="auto-start"
      overlay={popoverRole}
    >
      <div className={`employee-role ${role}`}>
        {role === 'Defender' && (
          <StaticImage
            src="../../../../images/counterside/icons/role_defender.png"
            alt={role}
          />
        )}
        {role === 'Ranger' && (
          <StaticImage
            src="../../../../images/counterside/icons/role_ranger.png"
            alt={role}
          />
        )}
        {role === 'Siege' && (
          <StaticImage
            src="../../../../images/counterside/icons/role_siege.png"
            alt={role}
          />
        )}
        {role === 'Sniper' && (
          <StaticImage
            src="../../../../images/counterside/icons/role_sniper.png"
            alt={role}
          />
        )}
        {role === 'Striker' && (
          <StaticImage
            src="../../../../images/counterside/icons/role_striker.png"
            alt={role}
          />
        )}
        {role === 'Supporter' && (
          <StaticImage
            src="../../../../images/counterside/icons/role_support.png"
            alt={role}
          />
        )}
        {role === 'Tower' && (
          <StaticImage
            src="../../../../images/counterside/icons/role_tower.png"
            alt={role}
          />
        )}
        {role === 'Rearm' && (
          <StaticImage
            src="../../../../images/counterside/icons/special_rearm.png"
            alt={role}
          />
        )}
        <span>{role}</span>
      </div>
    </OverlayTrigger>
  );
};
