import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { CSEmployeeRole } from '../../../modules/cs/common/components/employee-role';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const CSDangerClosePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Danger Close - mode overview</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_dangerclose_overview.png"
            alt="Danger Close Overview"
          />
        </div>
        <div className="page-details">
          <h1>Danger Close - mode overview</h1>
          <h2>
            This guide contains information about the Danger Close mode and the
            bosses you will fight there every two weeks.
          </h2>
          <p>
            Last updated: <strong>03/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content danger-close">
        <SectionHeader title="What is Danger Close?" />
        <p>
          Danger Close is a competitive PvE game mode where players compete by
          fighting a boss every other week. Each boss has 3 difficulty modes,
          and on the last difficulty, the players may choose additional risks
          for the fight to increase their score. Every season, the boss also
          comes with a set of units buffed and a debuff.
        </p>
        <SectionHeader title="Is it worth competing in?" />
        <p>
          For the most part - it’s only for bragging rights. Clearing just the
          9k score rewards - which should be doable even for casuals, gives 235
          DC points and some other rewards, while a gear in the DC shop costs
          400 points. Along with season rewards, semi-competitive players will
          be able to buy gear almost every week.
        </p>
        <SectionHeader title="Risks and scoring" />
        <p>
          The base score on killing a DC boss is based on the time left after
          killing the boss. On the third difficulty of each boss, the kill
          rewards 8000 points with additional points being rewarded for the time
          remaining, with 1 second roughly giving 28 points. The game tracks the
          kill time to milliseconds, making the base score very precise to the
          last digit.
        </p>
        <p>
          Risks are a vital part of DC, being the main score source after being
          able to kill the boss.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Risk</th>
              <th>Tier 1</th>
              <th>Score Bonus</th>
              <th>Tier 2</th>
              <th>Score Bonus</th>
              <th>Tier 3</th>
              <th>Score Bonus</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="left">Decreased ATK</td>
              <td>-10%</td>
              <td>+3%</td>
              <td>-25%</td>
              <td>+6%</td>
              <td>-50%</td>
              <td>+10%</td>
            </tr>
            <tr>
              <td className="left">Decreased HP</td>
              <td>-10%</td>
              <td>+3%</td>
              <td>-25%</td>
              <td>+6%</td>
              <td>-50%</td>
              <td>+10%</td>
            </tr>
            <tr>
              <td className="left">Decreased ASPD</td>
              <td>-10%</td>
              <td>+3%</td>
              <td>-25%</td>
              <td>+10%</td>
              <td>-40%</td>
              <td>+15%</td>
            </tr>
            <tr>
              <td className="left">Decreased Skill Haste</td>
              <td>-10%</td>
              <td>+3%</td>
              <td>-25%</td>
              <td>+10%</td>
              <td>-40%</td>
              <td>+15%</td>
            </tr>
            <tr>
              <td className="left">Increased Enemy HP</td>
              <td>+15%</td>
              <td>+3%</td>
              <td>+30%</td>
              <td>+6%</td>
              <td>+50%</td>
              <td>+10%</td>
            </tr>
            <tr>
              <td className="left">Increased Enemy ATK</td>
              <td>+15%</td>
              <td>+3%</td>
              <td>+30%</td>
              <td>+10%</td>
              <td>+50%</td>
              <td>+15%</td>
            </tr>
            <tr>
              <td className="left">Decreased Deployment Resource</td>
              <td>-30%</td>
              <td>+10%</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td className="left">Awakened Unit ATK, DEF, HP Decrease</td>
              <td>-20%</td>
              <td>+5%</td>
              <td>-40%</td>
              <td>+10%</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </tbody>
        </Table>
        <p>
          The player may choose as many debuffs as they like, but only one per
          type - you cannot pick both ATK -10% and ATK -25% in the same run.
        </p>
        <p>
          Now that we have the risks listed out, which ones are worth using?
        </p>
        <ul>
          <li>
            Decreased ATK and ASPD are taken when the player has enough damage
            to comfortably hamper themselves while still being able to kill the
            boss.
          </li>
          <li>
            Decreased HP and increased enemy ATK are taken when the player’s
            units are able to survive the boss well enough.
          </li>
          <li>
            Decreased skill haste is often used as long as there are no
            mechanics in which a certain level of haste is required for some
            units, such as healers needing to heal at a specific time.
          </li>
          <li>
            Decreased deployment resource is used when the player does not have
            a strict cost restraint on their team; while this risk gives a heavy
            score bonus, it also heavily changes strategies in some bosses, so
            be careful when picking it!
          </li>
          <li>
            Awakened unit stat decrease is the most commonly used risk because
            awakened units are not prevalent in Danger Close top runs; however,
            newer players may struggle to kill bosses without the help of
            awakened units. Generally, this buff is used when there is no
            awakened unit on the team.
          </li>
          <li>
            Increased enemy HP debuff makes the boss absurdly tanky and gives a
            feeble amount of bonus points. It’s rarely ever worth taking.
          </li>
        </ul>
        <p>
          Now you may be wondering: is it worth killing a boss slower if you can
          do it with a higher bonus from risks? The answer is simply, try it
          yourself and see which strategy scores higher! If you can estimate a
          kill time for the boss, you can also estimate your score for it, which
          would be calculated as:
        </p>
        <p>
          Score=[time left(in seconds) * (50 / 3) + 10000] * (1 + score bonus %)
        </p>
        <SectionHeader title="Bosses" />
        <p>
          In total there are 9 Danger Close bosses. Each requires a specific
          approach, or rather tactic, to kill.
        </p>
        <Tabs defaultActiveKey="mod-a" id="boss-tabs" className="mb-3">
          <Tab eventKey="mod-a" title="Mod-A">
            <div className="danger-close-card box">
              <div className="boss-image">
                <StaticImage
                  src="../../../images/counterside/generic/dcboss_modaa.png"
                  alt="Moderator Type A"
                />
              </div>
              <div className="boss-details">
                <h4>Moderator Type A</h4>
                <CSEmployeeRole role="Defender" />
              </div>
            </div>
            <h5>Description</h5>
            <p>
              A massive weapon of unknown origin from outside our dimension. It
              specializes in pulverizing approaching enemies, and is also
              capable of unsupported dimension jumps thanks to an engine system
              similar to a dimensional ship's.It may have been originally
              programmed to travel to and from the Counterside hunting C.O.s,
              but by the time it was discovered, it was out of control, having
              been damaged beyond repair by Corruption Rays.
            </p>
            <p className="highlight">
              ※ The Moderator receives additional damage when attacked in the
              rear, where it is vulnerable
            </p>
            <h5>Stats</h5>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>HP</th>
                  <th>ATK</th>
                  <th>DEF</th>
                  <th>CRIT</th>
                  <th>HIT</th>
                  <th>EVA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left">Moderator Type A Normal</td>
                  <td>80</td>
                  <td>570 323</td>
                  <td>4616</td>
                  <td>1288</td>
                  <td>627</td>
                  <td>648</td>
                  <td>432</td>
                </tr>
                <tr>
                  <td className="left">Moderator Type A Hard</td>
                  <td>100</td>
                  <td>901 141</td>
                  <td>7246</td>
                  <td>1868</td>
                  <td>816</td>
                  <td>938</td>
                  <td>624</td>
                </tr>
                <tr>
                  <td className="left">Moderator Type A Nightmare</td>
                  <td>130</td>
                  <td>2 070 294</td>
                  <td>11 961</td>
                  <td>2999</td>
                  <td>1124</td>
                  <td>1500</td>
                  <td>1000</td>
                </tr>
              </tbody>
            </Table>
            <h5>Videos</h5>
            <Row>
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="8PUrgnCubhs" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="mod-b" title="Mod-B">
            <div className="danger-close-card box">
              <div className="boss-image">
                <StaticImage
                  src="../../../images/counterside/generic/dcboss_modb.png"
                  alt="Moderator Type B"
                />
              </div>
              <div className="boss-details">
                <h4>Moderator Type B</h4>
                <CSEmployeeRole role="Ranger" />
              </div>
            </div>
            <h5>Description</h5>
            <p>
              A massive weapon of unknown origin from outside our dimension. It
              specializes in subduing distant enemies, and is also capable of
              unsupported dimension jumps thanks to an engine system similar to
              a dimensional ship's.It may have been originally programmed to
              travel to and from the Counterside hunting C.O.s, but by the time
              it was discovered, it was out of control, having been damaged
              beyond repair by Corruption Rays.
            </p>
            <p className="highlight">
              ※ The Moderator receives additional damage when attacked in the
              rear, where it is vulnerable.
            </p>
            <h5>Stats</h5>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>HP</th>
                  <th>ATK</th>
                  <th>DEF</th>
                  <th>CRIT</th>
                  <th>HIT</th>
                  <th>EVA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left">Moderator Type B Normal</td>
                  <td>80</td>
                  <td>525 783</td>
                  <td>3217</td>
                  <td>798</td>
                  <td>758</td>
                  <td>648</td>
                  <td>832</td>
                </tr>
                <tr>
                  <td className="left">Moderator Type B Hard</td>
                  <td>100</td>
                  <td>830 831</td>
                  <td>5279</td>
                  <td>1156</td>
                  <td>970</td>
                  <td>938</td>
                  <td>1203</td>
                </tr>
                <tr>
                  <td className="left">Moderator Type B Nightmare</td>
                  <td>130</td>
                  <td>1 867 263</td>
                  <td>12 548</td>
                  <td>1857</td>
                  <td>1558</td>
                  <td>1500</td>
                  <td>3000</td>
                </tr>
              </tbody>
            </Table>
            <h5>Videos</h5>
            <Row>
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="xNlEwYDVJsc" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="nervier" title="Nervier">
            <div className="danger-close-card box">
              <div className="boss-image">
                <StaticImage
                  src="../../../images/counterside/generic/dcboss_nervier.png"
                  alt="Danger Close Nervier"
                />
              </div>
              <div className="boss-details">
                <h4>Nervier</h4>
                <CSEmployeeRole role="Ranger" />
              </div>
            </div>
            <h5>Description</h5>
            <p>
              A Shadow in the hyperdepths of the Counterside. She hates every
              living thing and revels in hunting down those that run from her. A
              creature of the warped world, she's not bound by time or space,
              and is always threatening the Normalside beyond the walls.
            </p>
            <p className="highlight">
              ※ Nervier and Monolith specialize in fighting Ground units.
            </p>
            <h5>Stats</h5>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>HP</th>
                  <th>ATK</th>
                  <th>DEF</th>
                  <th>CRIT</th>
                  <th>HIT</th>
                  <th>EVA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left">Nervier Normal</td>
                  <td>80</td>
                  <td>471 808</td>
                  <td>7264</td>
                  <td>747</td>
                  <td>713</td>
                  <td>884</td>
                  <td>415</td>
                </tr>
                <tr>
                  <td className="left">Nervier Hard</td>
                  <td>100</td>
                  <td>766 898</td>
                  <td>11396</td>
                  <td>1081</td>
                  <td>791</td>
                  <td>1277</td>
                  <td>602</td>
                </tr>
                <tr>
                  <td className="left">Nervier Nightmare</td>
                  <td>130</td>
                  <td>1 493 862</td>
                  <td>19 850</td>
                  <td>1735</td>
                  <td>880</td>
                  <td>2045</td>
                  <td>1203</td>
                </tr>
              </tbody>
            </Table>
            <h5>Videos</h5>
            <Row>
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="B0-kqXEV4E0" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="sollicitatio" title="Sollicitatio">
            <div className="danger-close-card box">
              <div className="boss-image">
                <StaticImage
                  src="../../../images/counterside/generic/dcboss_soli.png"
                  alt="Sollicitatio"
                />
              </div>
              <div className="boss-details">
                <h4>Sollicitatio</h4>
                <CSEmployeeRole role="Siege" />
              </div>
            </div>
            <h5>Description</h5>
            <p>
              A noble being that has numerous sisters on the Counterside.
              Neither a Corrupted Object nor a Shadow, she's been freed from the
              eternal tether of fate and become one with the warped world,
              attaining immortality along the way. She's smarter than any human
              and stronger than any Shadow, but her arrogance may be her
              undoing.
            </p>
            <p className="highlight">
              ※ Defeat Sollicitatio before she reaches your ship. You can use
              Tower units to temporarily distract her
            </p>
            <h5>Stats</h5>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>HP</th>
                  <th>ATK</th>
                  <th>DEF</th>
                  <th>CRIT</th>
                  <th>HIT</th>
                  <th>EVA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left">Sollicitatio Normal</td>
                  <td>80</td>
                  <td>516 015</td>
                  <td>6757</td>
                  <td>1503</td>
                  <td>780</td>
                  <td>432</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td className="left">Sollicitatio Hard</td>
                  <td>100</td>
                  <td>815 413</td>
                  <td>10 602</td>
                  <td>2179</td>
                  <td>995</td>
                  <td>624</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td className="left">Sollicitatio Nightmare</td>
                  <td>130</td>
                  <td>2 167 361</td>
                  <td>21 006</td>
                  <td>3500</td>
                  <td>1355</td>
                  <td>1000</td>
                  <td>0</td>
                </tr>
              </tbody>
            </Table>
            <h5>Videos</h5>
            <Row>
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="XGusVUcwGjg" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="tyrant-armor" title="Tyrant Armor">
            <div className="danger-close-card box">
              <div className="boss-image">
                <StaticImage
                  src="../../../images/counterside/generic/dcboss_tyrantarmor.png"
                  alt="Danger Close Tyrant Armor"
                />
              </div>
              <div className="boss-details">
                <h4>Tyrant Armor</h4>
                <CSEmployeeRole role="Defender" />
              </div>
            </div>
            <h5>Description</h5>
            <p>
              A Category 3 C.O. observed regardless of the depth.Unlike the
              Beast type, which is dominated by instinct, it has the discretion
              to wait for an opportunity, thus posing a greater threat.Its
              combat style changes greatly depending on the armament floating
              around its main body.
            </p>
            <p className="highlight">
              ※ Attack with units with multiple-hit skills to interrupt Tyrant
              Armor's attack patterns.
            </p>
            <h5>Stats</h5>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>HP</th>
                  <th>ATK</th>
                  <th>DEF</th>
                  <th>CRIT</th>
                  <th>HIT</th>
                  <th>EVA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left">Tyrant Armor Normal</td>
                  <td>80</td>
                  <td>452 350</td>
                  <td>5099</td>
                  <td>1500</td>
                  <td>767</td>
                  <td>852</td>
                  <td>502</td>
                </tr>
                <tr>
                  <td className="left">Tyrant Armor Hard</td>
                  <td>100</td>
                  <td>714 684</td>
                  <td>8009</td>
                  <td>2111</td>
                  <td>980</td>
                  <td>1232</td>
                  <td>724</td>
                </tr>
                <tr>
                  <td className="left">Tyrant Armor Nightmare</td>
                  <td>130</td>
                  <td>1 416 578</td>
                  <td>13 938</td>
                  <td>3389</td>
                  <td>1353</td>
                  <td>1973</td>
                  <td>1158</td>
                </tr>
              </tbody>
            </Table>
            <h5>Videos</h5>
            <Row>
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="FkzF86j56bA" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="tyrant-sword" title="Tyrant Sword">
            <div className="danger-close-card box">
              <div className="boss-image">
                <StaticImage
                  src="../../../images/counterside/generic/dcboss_tyrantsword.png"
                  alt="Danger Close Tyrant Sword"
                />
              </div>
              <div className="boss-details">
                <h4>Tyrant Sword</h4>
                <CSEmployeeRole role="Striker" />
              </div>
            </div>
            <h5>Description</h5>
            <p>
              A Category 3 C.O. observed regardless of the depth.Unlike the
              Beast type, which is dominated by instinct, it has the discretion
              to wait for an opportunity, thus posing a greater threat.Its
              combat style changes greatly depending on the armament floating
              around its main body.
            </p>
            <p className="highlight">
              ※ Minimize the damage by decreasing the number of units within
              Tyrant Sword's range.
            </p>
            <h5>Stats</h5>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>HP</th>
                  <th>ATK</th>
                  <th>DEF</th>
                  <th>CRIT</th>
                  <th>HIT</th>
                  <th>EVA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left">Tyrant Sword Normal</td>
                  <td>80</td>
                  <td>609 567</td>
                  <td>6732</td>
                  <td>641</td>
                  <td>821</td>
                  <td>852</td>
                  <td>1000</td>
                </tr>
                <tr>
                  <td className="left">Tyrant Sword Hard</td>
                  <td>100</td>
                  <td>1 000 741</td>
                  <td>10 557</td>
                  <td>697</td>
                  <td>1041</td>
                  <td>1232</td>
                  <td>1446</td>
                </tr>
                <tr>
                  <td className="left">Tyrant Sword Nightmare</td>
                  <td>130</td>
                  <td>2 007 882</td>
                  <td>18 401</td>
                  <td>746</td>
                  <td>1384</td>
                  <td>1973</td>
                  <td>2317</td>
                </tr>
              </tbody>
            </Table>
            <h5>Videos</h5>
            <Row>
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="vUiCpDpbExM" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="reg-bishop" title="Reg. Bishop">
            <div className="danger-close-card box">
              <div className="boss-image">
                <StaticImage
                  src="../../../images/counterside/generic/dcboss_regenbishop.png"
                  alt="Regenerated Bishop"
                />
              </div>
              <div className="boss-details">
                <h4>Regenerated Bishop</h4>
                <CSEmployeeRole role="Striker" />
              </div>
            </div>
            <h5>Description</h5>
            <p>
              An officer of the Replacer Syndicate. She's a regenerated Replacer
              created as the Arbiter of System Terraside. According to the intel
              from the Administration, she's an evolved being that combines the
              old Replacer Bishop with the Qliphoth Replica Factor.\nShe seems
              to be affected by her source's memories, but she hasn't let it
              interfere with her performance as an officer of her organization,
              the hostile Replacer Syndicate.
            </p>
            <p className="highlight">
              ※ The Regenerated Bishop temporarily creates an Immortal clone
              during her Ultimate skill.
            </p>
            <h5>Stats</h5>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>HP</th>
                  <th>ATK</th>
                  <th>DEF</th>
                  <th>CRIT</th>
                  <th>HIT</th>
                  <th>EVA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left">Regenerated Bishop Normal</td>
                  <td>80</td>
                  <td>532 208</td>
                  <td>8271</td>
                  <td>1231</td>
                  <td>796</td>
                  <td>800</td>
                  <td>1569</td>
                </tr>
                <tr>
                  <td className="left">Regenerated Bishop Hard</td>
                  <td>100</td>
                  <td>840 778</td>
                  <td>12 965</td>
                  <td>1778</td>
                  <td>1007</td>
                  <td>1152</td>
                  <td>2262</td>
                </tr>
                <tr>
                  <td className="left">Regenerated Bishop Nightmare</td>
                  <td>130</td>
                  <td>1 381 745</td>
                  <td>22 573</td>
                  <td>2131</td>
                  <td>1336</td>
                  <td>1834</td>
                  <td>1441</td>
                </tr>
              </tbody>
            </Table>
            <h5>Videos</h5>
            <Row>
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="TcX9pX4ZoK4" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="reg-knight" title="Reg. Knight">
            <div className="danger-close-card box">
              <div className="boss-image">
                <StaticImage
                  src="../../../images/counterside/generic/dcboss_regenknight.png"
                  alt="Regen Knight"
                />
              </div>
              <div className="boss-details">
                <h4>Regenerated Knight</h4>
                <CSEmployeeRole role="Sniper" />
              </div>
            </div>
            <h5>Description</h5>
            <p>
              An officer of the Replacer Syndicate. She's a regenerated Replacer
              created as a guardian of System Terraside. According to the intel
              from the Administration, she's an evolved being that's been newly
              built from the bioinformation of the Replacer Knight. Everything
              about her, including personality and combat capabilities, is
              believed to have improved incomparably than before.
            </p>
            <p className="highlight">
              ※ Attack the Regenerated Knight and remove her barrier before its
              duration expires.
            </p>
            <h5>Stats</h5>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>HP</th>
                  <th>ATK</th>
                  <th>DEF</th>
                  <th>CRIT</th>
                  <th>HIT</th>
                  <th>EVA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left">Regenerated Knight Normal</td>
                  <td>80</td>
                  <td>384 630</td>
                  <td>8626</td>
                  <td>543</td>
                  <td>832</td>
                  <td>1552</td>
                  <td>208</td>
                </tr>
                <tr>
                  <td className="left">Regenerated Knight Hard</td>
                  <td>100</td>
                  <td>607 782</td>
                  <td>13 532</td>
                  <td>788</td>
                  <td>1051</td>
                  <td>2242</td>
                  <td>300</td>
                </tr>
                <tr>
                  <td className="left">Regenerated Knight Nightmare</td>
                  <td>130</td>
                  <td>1 065 489</td>
                  <td>25 096</td>
                  <td>1266</td>
                  <td>1390</td>
                  <td>3591</td>
                  <td>482</td>
                </tr>
              </tbody>
            </Table>
            <h5>Videos</h5>
            <Row>
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="0geezODYfAk" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="replacer-king" title="Replacer King">
            <div className="danger-close-card box">
              <div className="boss-image">
                <StaticImage
                  src="../../../images/counterside/generic/dcboss_king.png"
                  alt="Replacer King"
                />
              </div>
              <div className="boss-details">
                <h4>Replacer King</h4>
                <CSEmployeeRole role="Defender" />
              </div>
            </div>
            <h5>Description</h5>
            <p>
              The veiled leader of the Replacer Syndicate. He's been controlling
              his organization from behind without revealing himself. His
              endless psychological war with information agencies of different
              countries has given him the image of a demagogue or theorist. In
              reality, he's also a threatening combatant.
            </p>
            <p className="highlight">
              ※ Replacer King pushes away nearby enemies and inflicts
              significant skill damage on distant enemies.
            </p>
            <h5>Stats</h5>
            <p>Coming soon!</p>
            <h5>Videos</h5>
            <Row>
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="eDPnU8agIzc" />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="orochi" title="Orochi">
            <div className="danger-close-card box">
              <div className="boss-image">
                <StaticImage
                  src="../../../images/counterside/generic/dcboss_orochi.png"
                  alt="Danger Close Orochi"
                />
              </div>
              <div className="boss-details">
                <h4>Yamata no Orochi</h4>
                <CSEmployeeRole role="Ranger" />
              </div>
            </div>
            <h5>Description</h5>
            <p>
              A solitary being inhabiting the depths of Counterside, also known
              as Deathland. Once a goddess who brought prosperity to humans, she
              became a monster who despises humans after losing her beloved one
              at the hands of those seeking immortality. In place of her eight
              severed heads, she uses the body of the soulless priestess as a
              vessel to gather her strength and return to reality.
            </p>
            <p></p>
            <p className="highlight">
              ※ You must protect the orb from Orochi, who hinders allies'
              recovery ability.
            </p>
            <h5>Stats</h5>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>HP</th>
                  <th>ATK</th>
                  <th>DEF</th>
                  <th>CRIT</th>
                  <th>HIT</th>
                  <th>EVA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left">Yamata no Orochi Normal</td>
                  <td>80</td>
                  <td>482 825</td>
                  <td>9 901</td>
                  <td>1 781</td>
                  <td>1 241</td>
                  <td>2 108</td>
                  <td>790</td>
                </tr>
                <tr>
                  <td className="left">Yamata no Orochi Hard</td>
                  <td>100</td>
                  <td>737 712</td>
                  <td>15 019</td>
                  <td>2 486</td>
                  <td>1 729</td>
                  <td>2 933</td>
                  <td>1 101</td>
                </tr>
                <tr>
                  <td className="left">Yamata no Orochi Nightmare</td>
                  <td>130</td>
                  <td>1 152 466</td>
                  <td>23 295</td>
                  <td>3 650</td>
                  <td>2 529</td>
                  <td>4 291</td>
                  <td>1 608</td>
                </tr>
              </tbody>
            </Table>
            <h5>Videos</h5>
            <Row>
              <Col xs={12} lg={6}>
                <YoutubeEmbed embedId="63JWz1LiDBM" />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
    </DashboardLayout>
  );
};

export default CSDangerClosePage;

export const Head: React.FC = () => (
  <Seo
    title="Danger Close guide | Counter Side | Prydwen Institute"
    description="Guide for the Danger Close mode in Counter Side."
  />
);
